import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from "./layout.module.css"

const Layout = ({useSafeArea=true, children }) => {
  const iconHeight = 50;
  return (
    <div>
      <div class={styles.navbar}>
        <Link to="/">
          <StaticImage src="../images/icon.png" alt="Watch+ Icon" width={iconHeight} height={iconHeight} />
        </Link>
        <Link to="/blog">
          <p>BLOG</p>
        </Link>
      </div>
      <div className={useSafeArea ? styles.content : null} style={{marginTop: useSafeArea ? iconHeight + 10 : 0}}>
        {children}
      </div>
      <footer>
        <p>Watch+ Copyright © 2021 Krzysztof Rózga Mobile Apps</p>
      </footer>
    </div>
  )
}

export default Layout