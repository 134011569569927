// extracted by mini-css-extract-plugin
export var homeStyle = "index-module--homeStyle--1Zqrv";
export var landing = "index-module--landing--38_OW";
export var watchLeft = "index-module--watchLeft--3OQBI";
export var watchRight = "index-module--watchRight--1-WTY";
export var imgWatch = "index-module--imgWatch--2Lnds";
export var adaptiveTextAlignment = "index-module--adaptiveTextAlignment--3Zt4C";
export var fadein = "index-module--fadein--2YeVF";
export var fade = "index-module--fade--3nRUl";
export var fillScreen = "index-module--fillScreen--3euOp";
export var centered = "index-module--centered--1q-wa";
export var centerVertically = "index-module--centerVertically--ChkZa";
export var spaceMBottom = "index-module--spaceMBottom--1KxuU";
export var spaceLBottom = "index-module--spaceLBottom--2EKUw";
export var spaceLTop = "index-module--spaceLTop--cDWw4";
export var boldText = "index-module--boldText--1XKe5";
export var trademarkSpan = "index-module--trademarkSpan--2C9PO";
export var ctaButton = "index-module--ctaButton--1Bccs";
export var section = "index-module--section--3tH88";
export var phoneSection = "index-module--phoneSection--1kg2X";
export var imgPhone = "index-module--imgPhone--bUxXB";
export var f2 = "index-module--f2--vrx-7";
export var f3 = "index-module--f3--dhvA3";