import * as React from 'react'
import Layout from '../components/layout'
import * as styles from "./index.module.css"
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <Layout useSafeArea={false}>
      <title>Watch+ Widgets &#38; Wallpapers</title>
      <div className={styles.homeStyle}>
      <div className={styles.fillScreen}>
        <div className={styles.centered}>
          <div className={styles.landing}>
            <div className={styles.watchLeft}>
              <div className={styles.fadein}>
                <StaticImage id={styles.f3} className={styles.imgWatch} objectFit="contain" src="../images/location.png" />
                <StaticImage id={styles.f2} className={styles.imgWatch} objectFit="contain" src="../images/news.png" />
                <StaticImage id={styles.f1} className={styles.imgWatch} objectFit="contain" src="../images/keypad.png" />
              </div>
            </div>
            <div className={styles.centerVertically}>
              <h1 className={styles.spaceMBottom}>
                <span class={styles.boldText}>Powerful</span> widgets for your Apple Watch<span className={styles.trademarkSpan}>®</span>
              </h1>
              <a className={styles.ctaButton} href="https://apps.apple.com/us/app/watch-widgets-wallpapers/id1465017358">DOWNLOAD NOW</a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.centered}>
          <div className={styles.landing}>
            <div className={styles.adaptiveTextAlignment}>
              <h2>WIDGETS</h2>
              <h1 className={styles.spaceLBottom}>Unlock <span className={styles.boldText}>full potential</span> of your Apple Watch<span className={styles.trademarkSpan}>®</span></h1>
            </div>
            <div className={styles.watchRight}>
              <StaticImage className={styles.imgWatch} objectFit="contain" src="../images/news.png" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.centered}>
          <div className={styles.landing}>
            <div className={styles.adaptiveTextAlignment}>
              <h2>WALLPAPERS</h2>
              <h1 className={styles.spaceLBottom}>
                Find <span className={styles.boldText}>perfect wallpaper</span> in our Watch Face Gallery
                    </h1>
            </div>
            <div className={styles.watchRight}>
              <StaticImage className={styles.imgWatch} objectFit="contain" src="../images/wallpaper.png" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.phoneSection}>
        <div>
          <h2 className={styles.spaceLTop}>CUSTOMIZE</h2>
          <h1 className={styles.spaceLBottom}><span className={styles.boldText}>Control</span> with your iPhone<span className={styles.trademarkSpan}>®</span></h1>
        </div>
        <div className={styles.spaceLBottom}>
          <StaticImage className={styles.imgPhone} objectFit="contain" src="../images/control.png" />
        </div>
      </div>
      </div>
    </Layout>
  )
}

export default IndexPage